// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `currentSelectEnvironment().ts` with `currentSelectEnvironment().prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ICommonEnvironment } from 'utils/interfaces/environment.model';

export const environment = {
  name: 'dev',
  production: false,
  hmr: false,
  version: require('../../package.json').version,
  configs: {
    dev: {
      main_api: 'https://api.truedigitalvending.com/dev',
      sub_api: 'https://api.truedigitalvending.com/dev',
      firstPage: '/app/vm-status',
      firstPageId: 'vm_status',
      apiPwd: 'S7rWn6kJbf57jfHrjjsEVutfdY3AakJE',
      googleMap: {
        key: 'AIzaSyBKrAIfMWxZHXg_8fN8gWxuXJfupjeEPik',
        region: 'TH',
        language: 'th'
      },
      features: {
        shiftReportExportMovingProduct: false,
        downloadAllProductInBasketAsPFD: false,
        excelFunctionInCreateVM: true,
        versionControl: false,
        setBundleProductInBasket: true,
        appTheme: true,
      }
    }
  }
};

export const currentSelectEnvironment = (): ICommonEnvironment => {
  let envName = localStorage.getItem(`env::${window.location.origin}`);

  if (!envName || environment.configs[envName] === undefined) {
    for (const key in environment.configs) {
      if (Object.prototype.hasOwnProperty.call(environment.configs, key)) {
        envName = key;
        break;
      }
    }
  }

  return {
    ...environment,
    ...environment.configs[envName]
  };
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
