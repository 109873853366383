import { Injectable } from '@angular/core';
import { MasterType } from 'app/services/master-publish.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubHeaderService {

  // Content Margin Top
  public _contentMarginTop = new BehaviorSubject<string>('0px');
  public get contentMarginTop(): string {
    return this._contentMarginTop.value;
  }
  public set contentMarginTop(data: string) {
    this._contentMarginTop.next(data);
  }
  // Padding Bottom
  public _paddingBottom = new BehaviorSubject<string>('75px');
  public get paddingBottom(): string {
    return this._paddingBottom.value;
  }
  public set paddingBottom(data: string) {
    this._paddingBottom.next(data);
  }

  public _dataSubHeader = new BehaviorSubject<ISubHeader>(null);
  public get dataSubHeader(): ISubHeader {
    return this._dataSubHeader.value;
  }
  public set dataSubHeader(data: ISubHeader) {
    this._dataSubHeader.next(data);
  }

  public clickImport = new BehaviorSubject<boolean>(false);
  public clickExport = new BehaviorSubject<boolean>(false);
  public clickPublish = new BehaviorSubject<boolean>(false);
  public clickCustomBtn = new BehaviorSubject<boolean>(false);

  constructor() { }
}

export interface ISubHeader {
  title: string;
  pathBackBtn?: string;
  dateLatestSync?: string;
  pathRouteCreateBtn?: string;
  importBtn?: boolean;
  showImportBtn?: boolean;
  exportBtn?: boolean;
  showExportBtn?: boolean;
  showCustomBtn?: boolean;
  showCustomBtnConfig?: {
    icon?: string,
    text?: string,
    color?: string,
    canAction?: string,
  };
  publish?: {
    enable: boolean,
    masterType: MasterType,
    id: string
  };
}
