export const locale = {
  lang: 'th',
  data: {
    NAV: {
      APPLICATIONS: 'Menu',
      DASHBOARD: 'Dashboard',
      STOCK: 'Stock',
      VM_STATUS: 'Vending Status',
      STAFF: 'ข้อมูลพนักงาน',
      VENDING_CASH: {
        TITLE: 'Vending Cash',
        CASH_INVEN: 'Cash Inventory',
        CHANGE_COIN_ALERT: 'Change Coin Alert',
      },
      LOGANDHISTORY: {
        TITLE: 'Log & History',
        MACHINE_HISTORY: 'Machine History',
        VM_OPERATION: 'VM Operation',
        VM_PERFORMANCE: 'VM Performance',
      },
      REPORT: {
        TITLE: 'รายงาน',
        DAILY_SHIFT_REPORT: 'Sale Shift Report',
        SALE_TRAN_REPORT: 'Sales Report',
        STOCK: 'Stock',
        STOCK_FILL_REPORT: 'Stock Fill Report',
        STOCK_FILL_BY_PRODUCT_REPORT: 'Stock Fill Group By Product Report',
        STOCK_FILL_BY_DOTRAN_REPORT: 'Stock Fill Group By Transaction Report',
        STOCK_FILL_BY_DAY_REPORT: 'Stock Fill Group By Date Report',
        STOCK_CUTOFF_REPORT: 'Stock Cutoff Report',
        STOCK_ADJUST_REPORT: 'Stock Adjust Report',
        SALE_ERROR_LOG: 'Sale Error Log',
        HARDWARE_ERROR: 'Hardware Error',
        AUDIT_REPORT: 'Audit Report',
        SALE_FAIL_REPORT: 'Sale Error Report',
        CASH_MOVEMENT_REPORT: 'Cash Movement Report',
        DISPENSE_REPORT: 'Dispense Report',
        PROMOTION_REPORT: 'Sale By Promotion Report',
        ALL_SALE_TRAN_REPORT: 'All Sales Report',
        PLANOGRAM: 'Planogram',
        PLANOGRAM_REPORT: 'Planogram Report',
        PLANOGRAM_HISTORY_REPORT: 'Planogram History Report',
        COUPON_REPORT: 'Coupon Report',
        BBL: 'BBL',
        BBL_REPORT: 'BBL Report',
        FIND_BBL_TRAN: 'Find BBL Transaction',
        TOPUP_REPORT: 'Topup Report',
        FIND_PRODUCT: 'Find Product',
        CRC_REPORT: 'CRC Report',
        SALE_ECOMMERCE_REPORT: 'Sale Ecommerce Report',
      },
      PROMOTION_DATA: {
        TITLE: 'Promotion',
        PROMOTIONS: 'Promotions',
        PROMOTION_PLANS: 'Promotion Plans',
        TAGS: 'Tags',
      },
      COUPON_DATA: {
        COUPONS: 'Coupons',
      },
      VENDING_MASTER: {
        TITLE: 'Vending Master',
        VENDING_CONFIG: 'Vending Config',
        VENDING_PROFILE: 'Vending Profile',
        VENDING_CAT: 'Vending Segment',
        VENDING_GROUP: 'Vending Group',
        VENDING_TYPE: 'Vending Type',
        FULLFILLMENT: 'Fullfillment',
        SITEOWNER: 'Site Owner',
        TDS_RGM: 'TDS RGM',
        CBD: 'CBD',
      },
      MASTER_DATA: {
        TITLE: 'Master Data',
        COMPANY: 'Company',
        BRAND: 'Brand',
        BRANCH: 'Branch',
        PRODUCT: 'Product',
        TAG: 'Tag',
        PRODUCT_CAT: 'Product Category',
        PRODUCT_BASKET: 'Product Basket',
        BROWSING_MENU: 'Browsing Menu',
        SLOT_TEMPLATE: 'Planogram Template',
        THEME: 'Themes',
        HIDE_SLOT: 'Disable Product',
      },
      ROLE_MANAGEMENT: 'จัดการสิทธิ',
      MORE: {
        TITLE: 'More',
      },
      ADS_CAMPAIGN: {
        TITLE: 'Ads Full Screen',
        TITLE_MIDDLE: 'Ads Main Screen',
        TITLE_BOTTOM: 'Banner 2',
        CAMPAIGN: 'Ads Full Screen Campaign',
        ADS_DISPLAY_GROUP: 'Ads Full Screen',
        ADS_PLAN: 'Ads Full Screen Plan',
        CAMPAIGN_MIDDLE: 'Ads Main Screen Campaign',
        ADS_DISPLAY_GROUP_MIDDLE: 'Ads Main Screen',
        ADS_PLAN_MIDDLE: 'Ads Main Screen Campaign Plan',
        CAMPAIGN_BOTTOM: 'Banner 2 Campaign',
        ADS_DISPLAY_GROUP_BOTTOM: 'Banner 2 Display Group',
        ADS_PLAN_BOTTOM: 'Banner 2 Campaign Plan',
      },
      PAYMENT_CONFIG: {
        TITLE: 'Payment Config',
        TMN_PAYMENT: 'TMN Payment Config',
        TMN_BILL: 'TMN Bill Config',
        TMN_TOPUP: 'TMN Topup Config',
        WEOMNI_CONFIG: 'Weomni Config',
        BBL_CONFIG: 'BBL Config',
        BBL_BRANCH: 'BBL Branch Info',
      },
      COUPON_CAMPAIGN_DATA: {
        TITLE: 'Coupon Campaign',
        PARTNER: 'Partner',
        CAMPAIGN: 'Campaign',
        COUPON: ' Coupon',
        VOID: 'Void Coupon',
        REPORT: {
          SALE_SUCCESS: 'Coupon Success Report',
          SALE_SUCCESS_BY_PARTNER: 'Coupon By Partner Report',
          SALE_SUCCESS_BY_CAMPAIGN: 'Coupon By Campaign Report',
          SALE_FAILL: 'Coupon Fail Report',
          VOID_COUPON_HISTORY: 'Void Coupon History Report',
        },
      },
      E_COMMERCE: {
        TITLE: 'E-Commerce',
        PARTNER_SHOP: 'Partner Shop',
        CATAGORY: 'E-Commerce Category',
        PRODUCT: 'E-Commerce Product',
        REQUEST: 'E-Commerce Request Product',
      },
    },
  },
};
