import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { FuseNavigation } from '@fuse/types';
import { RolePage } from 'app/models/staff-role.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private historySubject = new BehaviorSubject<NavigationEnd[]>([]);
  public set history(value: NavigationEnd[]) {
    this.historySubject.next(value);
  }
  public get history(): NavigationEnd[] {
    return [...this.historySubject.getValue()];
  }

  constructor() { }

  public buildPage(items: FuseNavigation, rolePage: RolePage[] = []): RolePage[] {
    for (const item of items.children) {
      if (item.type === 'item') {
        rolePage.push(Object.assign(new RolePage(), {
            id: item.id,
            name: item.title,
            haveCreate: item.haveCreate || false,
            haveUpdate: item.haveUpdate || false,
            haveDelete: item.haveDelete || false,
            haveExport: item.haveExport || false,
          }));
      } else if (item.type === 'collapsable' || item.type === 'group') {
        this.buildPage(item, rolePage);
      }
    }

    return rolePage;
  }
}

export enum E_MenuId {
  dashboard = 'dashboard',
  stock = 'stock',
  vm_status = 'vm_status',

  // Master
  ms_company = 'ms_company',
  ms_brand = 'ms_brand',
  ms_branch = 'ms_branch',
  ms_product_category = 'ms_product_category',
  ms_product = 'ms_product',
  ms_tag = 'ms_tag',
  ms_basket = 'ms_basket',
  ms_menu = 'ms_menu',
  ms_vending_config = 'ms_vending_config',
  ms_vending_profile = 'ms_vending_profile',
  ms_vending_cat = 'ms_vending_cat',
  ms_vending_group = 'ms_vending_group',
  ms_vending_type = 'ms_vending_type',
  ms_fullfillment = 'ms_fullfillment',
  ms_siteowner = 'ms_siteowner',
  ms_tdsrgm = 'ms_tdsrgm',
  ms_cbd = 'ms_cbd',
  ms_promotions = 'ms_promotions',
  ms_promotion_plans = 'ms_promotion_plans',
  ms_ads_display_group = 'ms_ads_display_group',
  ms_ads_campaign = 'ms_ads_campaign',
  ms_ads_plan = 'ms_ads_plan',
  ms_ads_display_res = 'ms_ads_display_res',
  ms_ads_display_group_middle = 'ms_ads_display_group_middle',
  ms_ads_campaign_middle = 'ms_ads_campaign_middle',
  ms_ads_plan_middle = 'ms_ads_plan_middle',
  ms_ads_display_res_middle = 'ms_ads_display_res_middle',
  ms_ads_display_group_bottom = 'ms_ads_display_group_bottom',
  ms_ads_campaign_bottom = 'ms_ads_campaign_bottom',
  ms_ads_plan_bottom = 'ms_ads_plan_bottom',
  ms_ads_display_res_bottom = 'ms_ads_display_res_bottom',
  staff = 'staff',
  role_management = 'role_management',
  slot_template = 'slot_template',
  theme_template = 'theme_template',
  hide_slot = 'hide_slot',

  // Coupon
  ms_coupon = 'ms_coupon',

  // Coupon Campign
  ms_partner = 'ms_partner',
  ms_coupon_campaign = 'ms_coupon_campaign',
  ms_coupon_unit = 'ms_coupon_unit',
  ms_coupon_redeem = 'ms_coupon_redeem',

  // Payment Config
  ms_payment_tmn_bill_config = 'ms_payment_tmn_bill_config',
  ms_payment_tmn_topup_config = 'ms_payment_tmn_topup_config',
  ms_payment_tmn_payment_config = 'ms_payment_tmn_payment_config',
  ms_payment_weomni_config = 'ms_payment_weomni_config',
  ms_bbl_config = 'ms_bbl_config',
  ms_bbl_branch = 'ms_bbl_branch',

  // Report
  daily_shift_report = 'daily_shift_report',
  all_sales_tran_report = 'all_sales_tran_report',
  sales_tran_report = 'sales_tran_report',
  stock_fill_report = 'stock_fill_report',
  stock_fill_by_product_report = 'stock_fill_by_product_report',
  stock_fill_by_dotran_report = 'stock_fill_by_dotran_report',
  stock_fill_by_day_report = 'stock_fill_by_day_report',
  stock_cutoff_report = 'stock_cutoff_report',
  stock_adjust_report = 'stock_adjust_report',
  stock_error_log = 'stock_error_log',
  hardware_error = 'hardware_error',
  audit_report = 'audit_report',
  sale_fail_report = 'sale_fail_report',
  cash_movement_report = 'cash_movement_report',
  dispense_report = 'dispense_report',
  sale_promotion_report = 'sale_promotion_report',
  cash_inventory = 'cash_inventory',
  change_coin_alert = 'change_coin_alert',
  planogram_report = 'planogram_report',
  planogram_history_report = 'planogram_history_report',
  coupon_report = 'coupon_report',
  coupon_sale_report = 'coupon_sale_report',
  coupon_sale_partner_report = 'coupon_sale_partner_report',
  coupon_sale_campaign_report = 'coupon_sale_campaign_report',
  coupon_sale_fail_report = 'coupon_sale_fail_report',
  coupon_void_history_report = 'coupon_void_history_report',
  bbl_report = 'bbl_report',
  find_bbl_tran = 'find_bbl_tran',
  topup_report = 'topup_report',
  find_product = 'find_product',
  crc_report = 'crc_report',
  sale_ecommerce_report = 'sale_ecommerce_report',

  // E-Commerce Master
  ms_e_com_partner_shop = 'ms_partner_shop',
  ms_e_com_catagory = 'ms_e_com_catagory',
  ms_e_com_product = 'ms_e_com_product',
  ms_e_com_request = 'ms_e_com_request',

  // Log & History
  machine_history = 'machine_history',
  vm_operation = 'vm_operation',
  vm_performance = 'vm_performance'
}
