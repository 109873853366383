export const locale = {
  lang: 'en',
  data: {
    Create: 'Create',
    Update: 'Update',
    Cancel: 'Cancel',
    BRAND: 'Brand',
    COMPANY: 'Company',
    STAFF: 'Staff',
    ROLE: 'Role',
    LOGOUT: 'Logout',
    PICK_DATE_REPORT: 'Choose Date Report',
    CLOSE: 'Close',
    EXPORT: 'Export',
    START_DATE: 'Start Date',
    End_DATE: 'End Date',
    ALLOW_31_DAYS: 'Allow you to select 31 days',
    SEC: 'sec',
    FORM: {
      NAME: 'Name',
      DISCRIPTION: 'Discription',
      ENABLE: 'Enable',
      IMAGE: 'Image',
      INVALID: {
        REQUIRED: 'Required',
        EMAIL: 'Invalid email address',
        MUST_BE_8_LONG: 'Must be at least 8 characters long',
        AZ_09_ONLY: 'A-z or 0-9 only',
        ENG_OR_THAI: 'English or Thai only',
        ID_FORMAT: 'A-z, 0-9 and _ only',
        PHONENUMBER: 'Invalid telephone number (Ex: 0986458760)',
        UPPER_SYMBOL: 'Require at least 1 capital letter, numeric character [0-9] and symbol [@$!%*?&]',
        NUMBER_ONLY: 'numeric character [0-9] only',
        PASSWORD: 'A-z, [0-9] At least one letter and one number',
        USERNAME: 'A-z, 0-9 and . - _ @ only'
      }
    },
    TABLE: {
      ADDNEW: 'Add New',
      EXPORT: 'Export',
      SEARCH: 'Search'
    }
  }
};
