import { Injectable } from '@angular/core';
import { FuseNavigation } from '@fuse/types';
import { E_MenuId } from 'app/services/navigation.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public readonly baseMenuTools: FuseNavigation[] = [
    // {
    //   id: E_MenuId.dashboard,
    //   title: 'Dashboard',
    //   translate: 'NAV.DASHBOARD',
    //   type: 'item',
    //   isCustomIcon: true,
    //   icon: 'dashboard_icon',
    //   url: '/app/dashboard'
    // },
    {
      id: E_MenuId.stock,
      title: 'Stock',
      translate: 'NAV.STOCK',
      type: 'item',
      isCustomIcon: true,
      icon: 'stock_icon',
      url: '/app/stock',
    },
    {
      id: E_MenuId.vm_status,
      title: 'Vending Status',
      translate: 'NAV.VM_STATUS',
      type: 'item',
      isCustomIcon: true,
      icon: 'vm_status_icon',
      url: '/app/vm-status',
      exactMatch: true,
      haveCreate: true,
      haveUpdate: true,
      haveExport: true,
    },
    {
      id: 'logAndHistory',
      title: 'Log & History',
      translate: 'NAV.LOGANDHISTORY.TITLE',
      type: 'collapsable',
      icon: 'history',
      children: [
        {
          id: E_MenuId.machine_history,
          title: 'Machine History',
          translate: 'NAV.LOGANDHISTORY.MACHINE_HISTORY',
          type: 'item',
          url: 'app/history/machine-history',
          exactMatch: true,
          haveExport: true,
        },
        {
          id: E_MenuId.vm_operation,
          title: 'VM Operation',
          translate: 'NAV.LOGANDHISTORY.VM_OPERATION',
          type: 'item',
          url: 'app/vm-operation',
          exactMatch: true,
          haveExport: true,
          haveCreate: true,
          haveUpdate: true,
        },
        {
          id: E_MenuId.vm_performance,
          title: 'VM Performance',
          translate: 'NAV.LOGANDHISTORY.VM_PERFORMANCE',
          type: 'item',
          url: 'app/vm-operation/performance',
          exactMatch: true,
          haveExport: true,
        },
      ],
    },
    {
      id: 'vending_master_data',
      title: 'Vending Master',
      translate: 'NAV.VENDING_MASTER.TITLE',
      type: 'collapsable',
      icon: 'domain',
      children: [
        {
          id: E_MenuId.ms_vending_config,
          title: 'Vending Config',
          translate: 'NAV.VENDING_MASTER.VENDING_CONFIG',
          type: 'item',
          url: 'app/master-data/vending-config',
          exactMatch: true,
          haveCreate: true,
          haveUpdate: true,
        },
        // {
        //   id: E_MenuId.ms_vending_profile,
        //   title: 'Vending Profile',
        //   translate: 'NAV.VENDING_MASTER.VENDING_PROFILE',
        //   type: 'item',
        //   url: 'app/master-data/vending-profile',
        //   exactMatch: true
        // },
        {
          id: E_MenuId.ms_vending_cat,
          title: 'Vending Segment',
          translate: 'NAV.VENDING_MASTER.VENDING_CAT',
          type: 'item',
          url: 'app/master-data/vending-category',
          exactMatch: true,
          haveCreate: true,
          haveUpdate: true,
          haveDelete: true,
        },
        // {
        //   id: E_MenuId.ms_vending_group,
        //   title: 'Vending Group',
        //   translate: 'NAV.VENDING_MASTER.VENDING_GROUP',
        //   type: 'item',
        //   url: 'app/master-data/vending-group',
        //   exactMatch: true
        // },
        {
          id: E_MenuId.ms_vending_type,
          title: 'Vending Type',
          translate: 'NAV.VENDING_MASTER.VENDING_TYPE',
          type: 'item',
          url: 'app/master-data/vending-type',
          exactMatch: true,
          haveCreate: true,
          haveUpdate: true,
        },
        {
          id: E_MenuId.ms_fullfillment,
          title: 'Fulfillment',
          translate: 'NAV.VENDING_MASTER.FULLFILLMENT',
          type: 'item',
          url: 'app/vm-status/full-fill-ment',
          exactMatch: true,
          haveCreate: true,
          haveUpdate: true,
          haveDelete: true,
        },
        {
          id: E_MenuId.ms_siteowner,
          title: 'Site Owner',
          translate: 'NAV.VENDING_MASTER.SITEOWNER',
          type: 'item',
          url: 'app/vm-status/site-owner',
          exactMatch: true,
          haveCreate: true,
          haveUpdate: true,
          haveDelete: true,
        },
        {
          id: E_MenuId.ms_tdsrgm,
          title: 'TDS RGM',
          translate: 'NAV.VENDING_MASTER.TDS_RGM',
          type: 'item',
          url: 'app/vm-status/tds-rgm',
          exactMatch: true,
          haveCreate: true,
          haveUpdate: true,
          haveDelete: true,
        },
        {
          id: E_MenuId.ms_cbd,
          title: 'CBD',
          translate: 'NAV.VENDING_MASTER.CBD',
          type: 'item',
          url: 'app/vm-status/cbd',
          exactMatch: true,
          haveCreate: true,
          haveUpdate: true,
          haveDelete: true,
        },
      ],
    },
    {
      id: 'report',
      title: 'Report',
      translate: 'NAV.REPORT.TITLE',
      type: 'collapsable',
      isCustomIcon: true,
      icon: 'report_icon',
      children: [
        {
          id: E_MenuId.daily_shift_report,
          title: 'Sale Shift Report',
          translate: 'NAV.REPORT.DAILY_SHIFT_REPORT',
          type: 'item',
          url: '/app/report/daily-shift',
          exactMatch: true,
          haveExport: true,
        },
        {
          id: E_MenuId.all_sales_tran_report,
          title: 'All Sales Report',
          translate: 'NAV.REPORT.ALL_SALE_TRAN_REPORT',
          type: 'item',
          url: '/app/report/all-sales-tran',
          exactMatch: true,
          haveExport: true,
        },
        {
          id: E_MenuId.sales_tran_report,
          title: 'Sales Report',
          translate: 'NAV.REPORT.SALE_TRAN_REPORT',
          type: 'item',
          url: '/app/report/sales-tran',
          exactMatch: true,
          haveExport: true,
        },
        {
          id: 'stock_data',
          title: 'Stock',
          translate: 'NAV.REPORT.STOCK',
          type: 'collapsable',
          children: [
            {
              id: E_MenuId.stock_fill_report,
              title: 'Stock Fill Report',
              translate: 'NAV.REPORT.STOCK_FILL_REPORT',
              type: 'item',
              url: '/app/report/stock-fill',
              exactMatch: true,
              haveExport: true,
            },
            {
              id: E_MenuId.stock_fill_by_product_report,
              title: 'Stock Fill Group By Product Report',
              translate: 'NAV.REPORT.STOCK_FILL_BY_PRODUCT_REPORT',
              type: 'item',
              url: '/app/report/stock-fill-by-product',
              exactMatch: true,
              haveExport: true,
            },
            {
              id: E_MenuId.stock_fill_by_dotran_report,
              title: 'Stock Fill Group By DoTran Report',
              translate: 'NAV.REPORT.STOCK_FILL_BY_DOTRAN_REPORT',
              type: 'item',
              url: '/app/report/stock-fill-by-dotran',
              exactMatch: true,
              haveExport: true,
            },
            {
              id: E_MenuId.stock_fill_by_day_report,
              title: 'Stock Fill by Date Report',
              translate: 'NAV.REPORT.STOCK_FILL_BY_DAY_REPORT',
              type: 'item',
              url: '/app/report/stock-fill-by-day',
              exactMatch: true,
              haveExport: true,
            },
            {
              id: E_MenuId.stock_cutoff_report,
              title: 'Stock Cutoff Report',
              translate: 'NAV.REPORT.STOCK_CUTOFF_REPORT',
              type: 'item',
              url: '/app/report/stock-cutoff',
              exactMatch: true,
              haveExport: true,
            },
            {
              id: E_MenuId.stock_adjust_report,
              title: 'Stock Adjust Report',
              translate: 'NAV.REPORT.STOCK_ADJUST_REPORT',
              type: 'item',
              url: '/app/report/stock-adjust',
              exactMatch: true,
              haveExport: true,
            },
          ],
        },
        // {
        //   id: E_MenuId.stock_error_log,
        //   title: 'Sale Error Log',
        //   translate: 'NAV.REPORT.SALE_ERROR_LOG',
        //   type: 'item',
        //   url: '/app/report/sale-error',
        //   exactMatch: true
        // },
        {
          id: E_MenuId.hardware_error,
          title: 'Hardware Error',
          translate: 'NAV.REPORT.HARDWARE_ERROR',
          type: 'item',
          url: '/app/report/hw-error',
          exactMatch: true,
          haveExport: true,
        },
        // {
        //   id: E_MenuId.audit_report,
        //   title: 'Audit Report',
        //   translate: 'NAV.REPORT.AUDIT_REPORT',
        //   type: 'item',
        //   url: '/app/report/audit',
        //   exactMatch: true
        // }
        {
          id: E_MenuId.sale_fail_report,
          title: 'Sale Error Report',
          translate: 'NAV.REPORT.SALE_FAIL_REPORT',
          type: 'item',
          url: '/app/report/sale-fail',
          exactMatch: true,
          haveExport: true,
        },
        {
          id: E_MenuId.cash_movement_report,
          title: 'Cash Movement Report',
          translate: 'NAV.REPORT.CASH_MOVEMENT_REPORT',
          type: 'item',
          url: '/app/report/cash/cash-movement',
          exactMatch: true,
          haveExport: true,
        },
        // {
        //   id: E_MenuId.dispense_report,
        //   title: 'Dispense Report',
        //   translate: 'NAV.REPORT.DISPENSE_REPORT',
        //   type: 'item',
        //   url: '/app/report/cash/dispense',
        //   exactMatch: true,
        //   haveExport: true
        // },
        {
          id: E_MenuId.sale_promotion_report,
          title: 'Promotion Report',
          translate: 'NAV.REPORT.PROMOTION_REPORT',
          type: 'item',
          url: '/app/report/promotion',
          exactMatch: true,
          haveExport: true,
        },
        {
          id: 'planogram_data',
          title: 'Planogram',
          translate: 'NAV.REPORT.PLANOGRAM',
          type: 'collapsable',
          children: [
            {
              id: E_MenuId.planogram_report,
              title: 'Planogram Report',
              translate: 'NAV.REPORT.PLANOGRAM_REPORT',
              type: 'item',
              url: '/app/report/planogram',
              exactMatch: true,
              haveExport: true,
            },
            {
              id: E_MenuId.planogram_history_report,
              title: 'Planogram History Report',
              translate: 'NAV.REPORT.PLANOGRAM_HISTORY_REPORT',
              type: 'item',
              url: '/app/report/planogram-history',
              exactMatch: true,
              haveExport: true,
            },
          ],
        },
        {
          id: 'bbl_data',
          title: 'BBL',
          translate: 'NAV.REPORT.BBL',
          type: 'collapsable',
          children: [
            {
              id: E_MenuId.bbl_report,
              title: 'BBL Report',
              translate: 'NAV.REPORT.BBL_REPORT',
              type: 'item',
              url: '/app/report/bbl-tran',
              exactMatch: true,
              haveExport: true,
            },
            {
              id: E_MenuId.find_bbl_tran,
              title: 'Find BBL Transaction',
              translate: 'NAV.REPORT.FIND_BBL_TRAN',
              type: 'item',
              url: '/app/report/find-bbl-tran',
              exactMatch: true,
              haveExport: true,
            },
          ],
        },
        {
          id: E_MenuId.topup_report,
          title: 'Topup Report',
          translate: 'NAV.REPORT.TOPUP_REPORT',
          type: 'item',
          url: '/app/report/topup',
          exactMatch: true,
          haveExport: true,
        },
        {
          id: E_MenuId.find_product,
          title: 'Find Product',
          translate: 'NAV.REPORT.FIND_PRODUCT',
          type: 'item',
          url: '/app/report/find-product',
          exactMatch: true,
          haveExport: true,
        },
        {
          id: E_MenuId.crc_report,
          title: 'CRC Report',
          translate: 'NAV.REPORT.CRC_REPORT',
          type: 'item',
          url: '/app/report/crc',
          exactMatch: true,
          haveExport: true,
        },
        {
          id: E_MenuId.sale_ecommerce_report,
          title: 'Sale Ecommerce Report',
          translate: 'NAV.REPORT.SALE_ECOMMERCE_REPORT',
          type: 'item',
          url: '/app/report/sale-ecommerce',
          exactMatch: true,
          haveExport: true,
        },
      ],
    },
    {
      id: 'more',
      title: 'more',
      translate: 'NAV.MORE.TITLE',
      type: 'collapsable',
      isCustomIcon: true,
      icon: 'more_icon',
      children: [
        {
          id: 'vending_cash',
          title: 'Vending Cash',
          translate: 'NAV.VENDING_CASH.TITLE',
          type: 'collapsable',
          isCustomIcon: true,
          icon: 'vm_cash_icon',
          children: [
            {
              id: E_MenuId.cash_inventory,
              title: 'Cash Inventory',
              translate: 'NAV.VENDING_CASH.CASH_INVEN',
              type: 'item',
              url: '/app/cash/inventory',
              exactMatch: true,
              haveExport: true,
            },
            {
              id: E_MenuId.change_coin_alert,
              title: 'Change Coin Alert',
              translate: 'NAV.VENDING_CASH.CHANGE_COIN_ALERT',
              type: 'item',
              url: '/app/cash/coin-alert',
              exactMatch: true,
              haveExport: true,
            },
          ],
        },
        {
          id: 'coupon_data',
          title: 'Coupon Campaign',
          translate: 'NAV.COUPON_CAMPAIGN_DATA.TITLE',
          type: 'collapsable',
          isCustomIcon: true,
          icon: 'menu_coupon',
          children: [
            {
              id: E_MenuId.ms_partner,
              title: 'Partner',
              translate: 'NAV.COUPON_CAMPAIGN_DATA.PARTNER',
              type: 'item',
              url: 'app/coupon/partner',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_coupon_campaign,
              title: 'Campaign',
              translate: 'NAV.COUPON_CAMPAIGN_DATA.CAMPAIGN',
              type: 'item',
              url: 'app/coupon/campaign',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_coupon_unit,
              title: 'Coupon',
              translate: 'NAV.COUPON_CAMPAIGN_DATA.COUPON',
              type: 'item',
              url: '',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveExport: true,
              hidden: true,
            },
            {
              id: E_MenuId.ms_coupon_redeem,
              title: 'Void Coupon',
              translate: 'NAV.COUPON_CAMPAIGN_DATA.VOID',
              type: 'item',
              url: 'app/coupon/void',
              exactMatch: true,
              haveCreate: false,
              haveUpdate: true,
            },
            {
              id: E_MenuId.coupon_sale_report,
              title: 'Coupon Success Report',
              translate: 'NAV.COUPON_CAMPAIGN_DATA.REPORT.SALE_SUCCESS',
              type: 'item',
              url: 'app/coupon/report/sale',
              exactMatch: true,
              haveExport: true,
            },
            // {
            //   id: E_MenuId.coupon_sale_partner_report,
            //   title: 'Coupon By Partner Report',
            //   translate: 'NAV.COUPON_CAMPAIGN_DATA.REPORT.SALE_SUCCESS_BY_PARTNER',
            //   type: 'item',
            //   url: 'app/coupon/report/sale-by-partner',
            //   exactMatch: true,
            //   haveExport: true,
            // },
            // {
            //   id: E_MenuId.coupon_sale_campaign_report,
            //   title: 'Coupon By Campaign Report',
            //   translate: 'NAV.COUPON_CAMPAIGN_DATA.REPORT.SALE_SUCCESS_BY_CAMPAIGN',
            //   type: 'item',
            //   url: 'app/coupon/report/sale-by-campaign',
            //   exactMatch: true,
            //   haveExport: true,
            // },
            {
              id: E_MenuId.coupon_sale_fail_report,
              title: 'Coupon Fail Report',
              translate: 'NAV.COUPON_CAMPAIGN_DATA.REPORT.SALE_FAILL',
              type: 'item',
              url: 'app/coupon/report/sale-fail',
              exactMatch: true,
              haveExport: true,
            },
            {
              id: E_MenuId.coupon_void_history_report,
              title: 'Void Coupon History Report',
              translate: 'NAV.COUPON_CAMPAIGN_DATA.REPORT.VOID_COUPON_HISTORY',
              type: 'item',
              url: 'app/coupon/report/void-history',
              exactMatch: true,
              haveExport: true,
            },
          ],
        },
        {
          id: 'promotion_data',
          title: 'Promotion',
          translate: 'NAV.PROMOTION_DATA.TITLE',
          type: 'collapsable',
          isCustomIcon: true,
          icon: 'campaign_icon',
          children: [
            {
              id: E_MenuId.ms_promotions,
              title: 'Promotions',
              translate: 'NAV.PROMOTION_DATA.PROMOTIONS',
              type: 'item',
              url: 'app/promotions',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_promotion_plans,
              title: 'Promotion Plans',
              translate: 'NAV.PROMOTION_DATA.PROMOTION_PLANS',
              type: 'item',
              url: 'app/promotions/plan',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
          ],
        },
        {
          id: 'ads_campaign',
          title: 'Ads Full Screen',
          translate: 'NAV.ADS_CAMPAIGN.TITLE',
          type: 'collapsable',
          icon: 'music_video',
          children: [
            {
              id: E_MenuId.ms_ads_display_group,
              title: 'Ads Full Screen',
              translate: 'NAV.ADS_CAMPAIGN.ADS_DISPLAY_GROUP',
              type: 'item',
              url: 'app/campaign/ads-display-group',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_ads_campaign,
              title: 'Ads Full Screen Campaign',
              translate: 'NAV.ADS_CAMPAIGN.CAMPAIGN',
              type: 'item',
              url: 'app/campaign/ads-campaign',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_ads_plan,
              title: 'Ads Full Screen Plan',
              translate: 'NAV.ADS_CAMPAIGN.ADS_PLAN',
              type: 'item',
              url: 'app/campaign/ads-plan',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
          ],
        },
        {
          id: 'ads_campaign_middle',
          title: 'Ads Main Screen',
          translate: 'NAV.ADS_CAMPAIGN.TITLE_MIDDLE',
          type: 'collapsable',
          icon: 'music_video',
          children: [
            {
              id: E_MenuId.ms_ads_display_group_middle,
              title: 'Ads Main Screen',
              translate: 'NAV.ADS_CAMPAIGN.ADS_DISPLAY_GROUP_MIDDLE',
              type: 'item',
              url: 'app/campaign/ads-display-group-middle',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_ads_campaign_middle,
              title: 'Ads Main Screen Campaign',
              translate: 'NAV.ADS_CAMPAIGN.CAMPAIGN_MIDDLE',
              type: 'item',
              url: 'app/campaign/ads-campaign-middle',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_ads_plan_middle,
              title: 'Ads Main Screen Campaign Plan',
              translate: 'NAV.ADS_CAMPAIGN.ADS_PLAN_MIDDLE',
              type: 'item',
              url: 'app/campaign/ads-plan-middle',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
          ],
        },
        {
          id: 'ads_campaign_bottom',
          title: 'Banner 2',
          translate: 'NAV.ADS_CAMPAIGN.TITLE_BOTTOM',
          type: 'collapsable',
          icon: 'music_video',
          children: [
            {
              id: E_MenuId.ms_ads_display_group_bottom,
              title: '[ BOTTOM ] Ads Group',
              translate: 'NAV.ADS_CAMPAIGN.ADS_DISPLAY_GROUP_BOTTOM',
              type: 'item',
              url: 'app/campaign/ads-display-group-bottom',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_ads_campaign_bottom,
              title: 'Banner 2 Campaign',
              translate: 'NAV.ADS_CAMPAIGN.CAMPAIGN_BOTTOM',
              type: 'item',
              url: 'app/campaign/ads-campaign-bottom',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_ads_plan_bottom,
              title: 'Banner 2 Campaign Plan',
              translate: 'NAV.ADS_CAMPAIGN.ADS_PLAN_BOTTOM',
              type: 'item',
              url: 'app/campaign/ads-plan-bottom',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
          ],
        },
        {
          id: 'master_data',
          title: 'Master Data',
          translate: 'NAV.MASTER_DATA.TITLE',
          type: 'collapsable',
          isCustomIcon: true,
          icon: 'master_data_icon',
          children: [
            {
              id: E_MenuId.ms_company,
              title: 'Company',
              translate: 'NAV.MASTER_DATA.COMPANY',
              type: 'item',
              url: 'app/master-data/company',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            // {
            //   id: E_MenuId.ms_brand,
            //   title: 'Brand',
            //   translate: 'NAV.MASTER_DATA.BRAND',
            //   type: 'item',
            //   url: 'app/master-data/brand',
            //   exactMatch: true,
            //   haveCreate: true,
            //   haveUpdate: true
            // },
            // {
            //   id: E_MenuId.ms_branch,
            //   title: 'Branch',
            //   translate: 'NAV.MASTER_DATA.BRANCH',
            //   type: 'item',
            //   url: 'app/master-data/branch',
            //   exactMatch: true,
            //   haveCreate: true,
            //   haveUpdate: true
            // },
            {
              id: E_MenuId.ms_product_category,
              title: 'Product Category',
              translate: 'NAV.MASTER_DATA.PRODUCT_CAT',
              type: 'item',
              url: 'app/master-data/product-category',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_product,
              title: 'Product',
              translate: 'NAV.MASTER_DATA.PRODUCT',
              type: 'item',
              url: 'app/master-data/product',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveExport: true,
            },
            {
              id: E_MenuId.ms_tag,
              title: 'Tag',
              translate: 'NAV.MASTER_DATA.TAG',
              type: 'item',
              url: 'app/master-data/tag',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveDelete: true,
            },
            {
              id: E_MenuId.ms_basket,
              title: 'Product Basket',
              translate: 'NAV.MASTER_DATA.PRODUCT_BASKET',
              type: 'item',
              url: 'app/master-data/basket',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveDelete: true,
              haveExport: true,
            },
            {
              id: E_MenuId.ms_menu,
              title: 'Browsing Menu',
              translate: 'NAV.MASTER_DATA.BROWSING_MENU',
              type: 'item',
              url: 'app/master-data/menu',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveDelete: true,
            },
            {
              id: E_MenuId.slot_template,
              title: 'Planogram Template',
              translate: 'NAV.MASTER_DATA.SLOT_TEMPLATE',
              type: 'item',
              url: 'app/master-data/slot-template',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.hide_slot,
              title: 'Disable Product',
              translate: 'NAV.MASTER_DATA.HIDE_SLOT',
              type: 'item',
              url: 'app/master-data/hide-slot',
              exactMatch: true,
              haveCreate: true,
              haveExport: true,
            },
          ],
        },
        {
          id: 'payment_config',
          title: 'Payment Config',
          translate: 'NAV.PAYMENT_CONFIG.TITLE',
          type: 'collapsable',
          icon: 'payment',
          children: [
            {
              id: E_MenuId.ms_payment_tmn_payment_config,
              title: 'TMN Payment Config',
              translate: 'NAV.PAYMENT_CONFIG.TMN_PAYMENT',
              type: 'item',
              url: 'app/payment-config/tmn-payment',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveDelete: true,
            },
            {
              id: E_MenuId.ms_payment_tmn_bill_config,
              title: 'TMN Bill Config',
              translate: 'NAV.PAYMENT_CONFIG.TMN_BILL',
              type: 'item',
              url: 'app/payment-config/tmn-bill',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveDelete: true,
            },
            {
              id: E_MenuId.ms_payment_tmn_topup_config,
              title: 'TMN Topup Config',
              translate: 'NAV.PAYMENT_CONFIG.TMN_TOPUP',
              type: 'item',
              url: 'app/payment-config/tmn-topup',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveDelete: true,
            },
            {
              id: E_MenuId.ms_payment_weomni_config,
              title: 'Weomni Config',
              translate: 'NAV.PAYMENT_CONFIG.WEOMNI_CONFIG',
              type: 'item',
              url: 'app/payment-config/weomni-config',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveDelete: true,
            },
            {
              id: E_MenuId.ms_bbl_config,
              title: 'BBL Config',
              translate: 'NAV.PAYMENT_CONFIG.BBL_CONFIG',
              type: 'item',
              url: 'app/payment-config/bbl-config',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveDelete: true,
              hidden: true,
            },
            {
              id: E_MenuId.ms_bbl_branch,
              title: 'BBL Branch Info',
              translate: 'NAV.PAYMENT_CONFIG.BBL_BRANCH',
              type: 'item',
              url: 'app/payment-config/bbl-branch',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
              haveDelete: true,
              hidden: true,
            },
          ],
        },
        {
          id: 'e_com',
          title: 'E-Commerce',
          translate: 'NAV.E_COMMERCE.TITLE',
          type: 'collapsable',
          isCustomIcon: true,
          icon: 'ecom_icon',
          children: [
            {
              id: E_MenuId.ms_e_com_partner_shop,
              title: 'Partner Shop',
              translate: 'NAV.E_COMMERCE.PARTNER_SHOP',
              type: 'item',
              url: 'app/e-commerce/partner-shop',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_e_com_catagory,
              title: 'E-Commerce Category',
              translate: 'NAV.E_COMMERCE.CATAGORY',
              type: 'item',
              url: 'app/e-commerce/category',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_e_com_product,
              title: 'E-Commerce Product',
              translate: 'NAV.E_COMMERCE.PRODUCT',
              type: 'item',
              url: 'app/e-commerce/product',
              exactMatch: true,
              haveCreate: true,
              haveUpdate: true,
            },
            {
              id: E_MenuId.ms_e_com_request,
              title: 'E-Commerce Request Product',
              translate: 'NAV.E_COMMERCE.REQUEST',
              type: 'item',
              url: 'app/e-commerce/request',
              exactMatch: true,
              haveUpdate: true,
            },
          ],
        },
        {
          id: E_MenuId.theme_template,
          title: 'Themes',
          translate: 'NAV.MASTER_DATA.THEME',
          type: 'item',
          icon: 'brush',
          url: 'app/master-data/theme',
          exactMatch: true,
          haveCreate: true,
          haveUpdate: true,
        },
        {
          id: E_MenuId.staff,
          title: 'Staff',
          translate: 'NAV.STAFF',
          type: 'item',
          isCustomIcon: true,
          icon: 'auth_icon',
          url: '/app/staff',
          exactMatch: true,
          haveCreate: true,
          haveUpdate: true,
          haveDelete: true,
        },
        {
          id: E_MenuId.role_management,
          title: 'Role Management',
          translate: 'NAV.ROLE_MANAGEMENT',
          type: 'item',
          icon: 'perm_data_setting',
          url: '/app/role',
          exactMatch: true,
          haveCreate: true,
          haveUpdate: true,
        },
      ],
    },
  ];

  public menuTool$ = new BehaviorSubject<FuseNavigation[]>([]);

  constructor() {}

  public getObs(): Observable<FuseNavigation[]> {
    return this.menuTool$.asObservable();
  }
}
