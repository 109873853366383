import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, ActivatedRoute, Router } from '@angular/router';
import { RoleConsole, RolePage } from 'app/models/staff-role.model';
import { AuthService } from 'app/services/auth/auth.service';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ISubHeader, SubHeaderService } from 'utils/services/sub-header.service';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit, OnDestroy {

  private _unsubscribeAll = new Subject<any>();

  public data: ISubHeader;
  public paddingBottom = '0px';

  public canCreate = false;
  public canExport = false;

  public blockCustomBtn = false;

  public pageId = '';

  constructor(
    private subHeaderService: SubHeaderService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.router.events.pipe(
      takeUntil(this._unsubscribeAll),
      filter(e => e instanceof NavigationEnd),
      map(() => this.rootRoute(this.route)),
      filter((r: ActivatedRoute) => r.outlet === 'primary'),
      mergeMap((r: ActivatedRoute) => r.data)
    ).subscribe(
      (res) => {
        this.pageId = res.page_id;
        this.canCreate = this.checkShowButton(this.pageId, 'canCreate');
        this.canExport = this.checkShowButton(this.pageId, 'canExport');
      }
    );
  }

  ngOnInit(): void {
    this.subHeaderService._dataSubHeader
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.data = data;
      });
    this.subHeaderService._paddingBottom
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((px) => {
        this.paddingBottom = px;
      });

    this.subHeaderService.clickCustomBtn.pipe(
      takeUntil(this._unsubscribeAll)
    )
      .subscribe(
        (res) => {
          this.blockCustomBtn = res;
        }
      );
  }

  public onClickExport(): void {
    this.subHeaderService.clickExport.next(true);
  }

  public onClickImport(): void {
    this.subHeaderService.clickImport.next(true);
  }

  public onClickCustomBtn(): void {
    this.subHeaderService.clickCustomBtn.next(true);
  }

  public onClickPublish(): void {
    this.subHeaderService.clickPublish.next(true);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  public checkShowButton(pageId: string, node: string): boolean {
    const staff = this.authService.staff;

    if (staff === null) {
      return false;
    }

    let check = false;
    if (staff.staff_role?.access) {
      const parse = staff.staff_role.access as RoleConsole;
      const idxPage = parse.pages.findIndex((e) => e.id === pageId);
      if (idxPage !== -1) {
        const rolePage: RolePage = parse.pages[idxPage];
        check = rolePage[node];
      }
    }

    return check;
  }

}
